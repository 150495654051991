var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "upload-files" },
    [
      _c(
        "input",
        _vm._b(
          {
            ref: "file",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              multiple: _vm.limit > 1,
              disabled: _vm.disabled,
              accept:
                _vm.accept ||
                _vm.fileTypes
                  .map(function(i) {
                    return "." + i
                  })
                  .join(",")
            },
            on: { change: _vm.handleChange }
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }