import "core-js/modules/es.array.includes";import "core-js/modules/es.array.join";import "core-js/modules/es.function.name";import "core-js/modules/es.number.constructor";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.includes";import "core-js/modules/es.string.split"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 *  上传文件 校验
 *  this.$emit('error', '')  文件校验错误
 *  this.$emit('submit', files)  文件列表
 */
var KB = 1024;

export default {
  name: 'upload-files',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      "default": false },

    accept: {
      type: String,
      "default": '' },

    // 文件个数
    limit: {
      type: Number,
      "default": 1 },

    // 文件大小限制
    size: {
      type: Number,
      "default": 10240 },

    // 文件后缀名限制 例： ['jpg','jpeg','xls']
    fileTypes: {
      type: Array,
      "default": function _default() {return [];} } },


  data: function data() {
    return {
      files: [] };

  },
  methods: {
    // 组件调用
    chooseFiles: function chooseFiles() {
      this.$refs.file.click();
    },
    handleChange: function handleChange() {var
      files = this.$refs.file.files;

      if (files.length > this.limit) {
        return this.handleError('limit', "\u6587\u4EF6\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7\uFF1A".concat(this.limit, " \uFF01"));
      }
      this.files = files;
      this.handleSubmit();
    },
    checkType: function checkType(file) {
      // if (!file.type) {
      //   return false
      // }
      var suffix = file.name.split('.').pop();

      file.suffix = suffix;
      return !this.fileTypes.length || this.fileTypes.includes(suffix);
    },
    checkSize: function checkSize(file) {
      if (isNaN(this.size) || this.size < 0) {
        return true;
      }
      return file.size < this.size * KB;
    },
    checkFiles: function checkFiles(files, methods) {
      for (var i = 0; i < files.length; i += 1) {
        if (!methods(files[i])) {
          return false;
        }
      }
      return true;
    },
    handleSubmit: function handleSubmit() {var
      files = this.files;

      if (!this.checkFiles(files, this.checkType)) {
        return this.handleError(
        'type', "\u6587\u4EF6\u683C\u5F0F\u53EA\u80FD\u662F\uFF1A".concat(
        this.fileTypes.join('、'), " \uFF01"));

      }
      if (!this.checkFiles(files, this.checkSize)) {
        return this.handleError('size', "\u6587\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7\uFF1A".concat(this.size, " kb\uFF01"));
      }
      this.$emit('submit', this.files);
      this.$refs.file.value = '';
    },
    handleError: function handleError(type, msg) {
      this.$emit('error', type, msg);
      this.$refs.file.value = '';
    } } };